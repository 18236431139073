import { isClientSide, getAccessToken } from '@socar-inc/web-frontend-libraries'
import axios from 'axios'

import { settings } from 'configs/env-config/settings'

const instance = axios.create({
  baseURL: settings.API_DOMAIN,
})

instance.defaults.timeout = 1000 * 20

const appToken = isClientSide ? getAccessToken() : ''

if (appToken) {
  instance.defaults.headers.common.Authorization = `Bearer ${appToken}`
}

export default instance
