import {
  useQuery as useQueryOrigin,
  UseQueryOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

export type QueryKey = readonly unknown[]

export function useCustomQuery<TResult>(
  queryKey: QueryKey,
  queryFn: (parmas?: unknown) => Promise<TResult>,
  options: UseQueryOptions<TResult, AxiosError> = {}
) {
  return useQueryOrigin<TResult, AxiosError>(queryKey, queryFn, {
    ...options,
  })
}
