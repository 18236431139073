import { AxiosRequestConfig } from 'axios'

import axiosInstance from './axiosInstance'

export function getFetcher<T = unknown>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return axiosInstance.get<T>(url, config).then((res) => res.data)
}

export function putFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return axiosInstance.put<T>(url, data, config).then((res) => res.data)
}

export function patchFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return axiosInstance.patch<T>(url, data, config).then((res) => res.data)
}

export function postFetcher<T = unknown>(
  url: string,
  data?: Record<string, any>,
  config?: AxiosRequestConfig<any>
) {
  return axiosInstance.post<T>(url, data, config).then((res) => res.data)
}

export function deleteFetcher<T>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return axiosInstance.delete<T>(url, config).then((res) => res.data)
}
